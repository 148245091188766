import * as React from "react";

import PropTypes from "prop-types";
import Layout from "../components/Layout";
import NewsRoll from "../components/NewsRoll";
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import { graphql } from "gatsby";


// eslint-disable-next-line
export const NewsPageTemplate = ({
  image,
  title,
  subtitle
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage img={heroImage} title="" subheading={subtitle} />
      <section className="section">
        <div className="container">
          <div className="content">
            <NewsRoll />
          </div>
        </div>
     </section>
    </div>
  );
};

NewsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string
};

const NewsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <NewsPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
      />
    </Layout>
  );
};

NewsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default NewsPage;

export const pageQuery = graphql`
  query NewsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "news-page" } }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
